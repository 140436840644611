.formContentBox[data-v-4ae7b8f2] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-4ae7b8f2] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.step-box[data-v-4ae7b8f2] {
  background-color: #ffffff;
  width: 125px;
}
.form-box[data-v-4ae7b8f2] {
  background-color: #ffffff;
  width: calc(100% - 135px);
  height: 100%;
  overflow: scroll;
}
.list-decoration[data-v-4ae7b8f2] {
  text-decoration: underline;
}
.leftRightBtn[data-v-4ae7b8f2] {
  margin: 211px 12px;
}
.checklist-edit[data-v-4ae7b8f2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.content[data-v-4ae7b8f2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content .img-content[data-v-4ae7b8f2] {
  width: 188px;
  height: 100px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.content .img-content .delete-icon[data-v-4ae7b8f2] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.content .img-content img[data-v-4ae7b8f2] {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}